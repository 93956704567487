import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AxiosError } from 'axios';
import { notification, PaginationProps, Table, Typography } from 'antd';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { useMediaQuery } from 'react-responsive';
import qs from 'qs';
import type { ResizeCallbackData } from 'react-resizable';

import TrendsService from '../../../services/trends';
import { dateRange } from '../../../utils/lastThreeMonths';
import { ChangeInfoModal } from './InformationModal';
import { useColumns } from './useColumns';
import { ResizableColumnTitle } from '../../../components/ResizableColumnTitle/ResizableColumnTitle';
import styles from './styles.module.scss';

const { Text } = Typography;

type Props = {
  tab: string;
  pageName: string;
  rule?: any;
};

export const TrendsTable = ({ tab, pageName, rule }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [tableData, setTableData] = useState<any[]>([]);
  const [total, setTotal] = useState<number>(0);
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const defaultEndDate = format(new Date().setMonth(new Date().getMonth() - 1), 'yyyy-MM');
  const defaultStartDate = format(new Date().setMonth(new Date().getMonth() - 6), 'yyyy-MM');
  const [openModal, setOpenModal] = useState(false);
  const [isShareActive, setIsShareActive] = useState<boolean>(true);

  const page = +queryParams!.get('page') || 1;
  const limit = +queryParams!.get('limit') || 10;
  const [totalInfo, setTotalInfo] = useState<any[]>([]);
  const regions = rule?.regions?.join(',');

  const onPageChange: PaginationProps['onChange'] = (page, pageSize) => {
    queryParams.set('page', `${page}`);
    queryParams.set('limit', `${pageSize}`);
    history.replace({ pathname: history.location.pathname, search: queryParams.toString() });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const { data, isFetching, error } = useQuery(['trendsTable', tab, pageName, location.search], ({ signal }) => {
    const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
    return TrendsService.getTrendsReports(
      pageName === 'company' ? 'local_company' : pageName,
      {
        measure: tab,
        months: params?.months || dateRange(defaultStartDate, defaultEndDate).join(','),
        local_company_id: params?.local_company,
        size: +params?.limit || 10,
        city_id: params?.city || regions || '24',
        common_stores: params.active_stores,
        subcategory_id: params?.subcategory,
        ...params,
      },
      { signal }
    );
  });

  const openNotification = (msgTitle: string, msgDescription?: string) => {
    notification.info({
      message: msgTitle,
      description: msgDescription,
      placement: 'bottomLeft',
    });
  };

  useEffect(() => {
    if (data) {
      setTableData(data?.data?.results);
      setTotal(data?.data?.count);
      setTotalInfo(data?.data?.additional[0].data);
    }
    if (error instanceof AxiosError) {
      if (error?.response?.status === 400) {
        openNotification(error?.response?.data?.['months']?.[0]);
        setTableData([]);
        setTotal(0);
        setTotalInfo([]);
      }
      if (error?.response?.status === 403) {
        openNotification('You do not have permission to perform this action.');
        setTableData([]);
        setTotal(0);
        setTotalInfo([]);
      } else {
        openNotification('Data with your request not found');
        setTableData([]);
        setTotal(0);
        setTotalInfo([]);
      }
    }

    return () => {
      setTableData([]);
      setTotal(0);
      setTotalInfo([]);
    };
  }, [data, error]);

  const columnsParams = useMemo(
    () => ({ pageName, isMobile: isTabletOrMobile, tab, tableData, setOpenModal, isShareActive, setIsShareActive }),
    [pageName, isTabletOrMobile, tab, tableData, setOpenModal, isShareActive, setIsShareActive]
  );
  const columnsMemo = useColumns(columnsParams);
  const [columns, setColumns] = useState(columnsMemo);

  const handleResize: Function = useCallback(
    (index: number) =>
      (_: React.SyntheticEvent<Element>, { size }: ResizeCallbackData) => {
        const newColumns = [...columns];
        newColumns[index] = {
          ...newColumns[index],
          width: size.width,
        };
        setColumns(newColumns);
      },
    [columns]
  );

  const isColumnsChanged = useCallback(() => {
    if (columnsParams) {
      return true;
    }
  }, [columnsParams]);

  const memoizedColumns = useMemo(() => {
    columns.map((col) => ({
      ...col,
      onHeaderCell: (column) => {},
    }));

    return columns.map((col, index) => ({
      ...col,
      onHeaderCell: (column) => {
        return {
          width: column.width,
          onResize: column?.children
            ? column?.children?.map((secondCol, secondIndex) => ({
                ...secondCol,
                onHeaderCell: (secColumn) => ({
                  width: secColumn.width,
                  onResize: handleResize(secondIndex),
                }),
              }))
            : handleResize(index),
        };
      },
    }));
  }, [columns, handleResize]);

  useEffect(() => {
    if (isColumnsChanged) {
      setColumns(columnsMemo);
    }
  }, [isColumnsChanged]);

  return (
    <>
      <Table
        columns={memoizedColumns.filter((item: any) => !item['hidden'])}
        dataSource={tableData}
        loading={isFetching}
        pagination={{
          pageSize: limit,
          defaultPageSize: 10,
          total: total,
          onChange: onPageChange,
          current: page,
          defaultCurrent: 1,
        }}
        className={styles.ant_table}
        sticky
        scroll={{ x: '1300' }}
        summary={() => {
          return (
            <>
              <Table.Summary.Row>
                <Table.Summary.Cell index={0}>
                  <Text className={styles.total_bold}>Total</Text>
                </Table.Summary.Cell>
                {pageName === 'barcode' && <Table.Summary.Cell index={1} />}
                {totalInfo.map((item) => (
                  <>
                    <Table.Summary.Cell index={1} align="right">
                      <span className={styles.summary_cell}>
                        <Text className={styles.total_bold}>
                          {`${item?.linear}`?.replace(/(\d)(?=(\d{3})+$)/g, '$1 ')}
                        </Text>
                        {/* <Text className={styles.total_bold}>Share: {item?.share || '--'} %</Text> */}
                      </span>
                    </Table.Summary.Cell>

                    <Table.Summary.Cell index={2} align="right">
                      <span className={styles.summary_cell}>
                        {isShareActive ? (
                          <>
                            <Text className={styles.total_bold}>Absolute: {item?.share_absolute || '--'} %</Text>
                            <Text className={styles.total_bold}>Relative: {item?.share_relative || '--'} %</Text>
                          </>
                        ) : (
                          <>
                            <Text className={styles.total_bold}>Absolute: {item?.absolute || '--'} %</Text>
                            <Text className={styles.total_bold}>Relative: {item?.relative || '--'} %</Text>
                          </>
                        )}
                      </span>
                    </Table.Summary.Cell>
                  </>
                ))}
              </Table.Summary.Row>
            </>
          );
        }}
        components={{
          header: {
            cell: ResizableColumnTitle,
          },
        }}
        bordered
        size="middle"
      />
      <ChangeInfoModal open={openModal} close={handleCloseModal} />
    </>
  );
};
