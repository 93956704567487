import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { PageQueryParams } from '../models/pageQueryParams';
import { fetchClient, reportsApiClient as reportsApi } from './interceptor';

interface TrendsQueryParams extends PageQueryParams {
  months: string;
  measure: string;
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}

interface TrendsReportsQueryParams extends PageQueryParams {
  months: string;
  measure: string;
  city_id?: string | number;
  brand_id?: string;
  barcode?: string;
  local_company_id?: string;
  size_of_package_id?: string;
  subcategory_id?: string;
  type_of_package_id?: string;
  active_stores?: string | boolean;
  common_stores?: boolean | string;
  battery_capacity_id?: string;
  validated_stores?: boolean;
}
export default class TrendsService {
  static async getTrendsByCompany(
    params: TrendsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/trends/graph/local_company/`, { params, ...customConfig });
  }

  static async getTrendsReports(
    type: string,
    params: TrendsReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/trends/table/${type}`, { params, ...customConfig });
  }

  static async getTrendsByBrand(params: TrendsQueryParams, customConfig?: AxiosRequestConfig): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/trends/graph/brand/`, { params, ...customConfig });
  }

  static async getTrendsByBarcode(
    params: TrendsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/trends/graph/barcode/`, { params, ...customConfig });
  }

  static async getAllTrendsByCompany(
    params: TrendsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const config = {
      params,
      ...customConfig,
    };
    return fetchClient.get(`/api/v1/trends/table/local_company/`, config);
  }

  static async getAllTrendsByBrand(
    params: TrendsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const config = {
      params,
      ...customConfig,
    };
    return fetchClient.get(`/api/v1/trends/table/brand/`, config);
  }

  static async getAllTrendsByBarcode(
    params: TrendsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    const config = {
      params,
      ...customConfig,
    };
    return fetchClient.get(`/api/v1/trends/table/barcode/`, config);
  }

  static async exportByType(
    type: string,
    params: TrendsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/trends/table/${type}/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportReportsByType(
    type: string,
    params: TrendsReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/trends/export/${type}`, {
      params,
      ...customConfig,
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' },
    });
  }
}
