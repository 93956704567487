import { AxiosRequestConfig, AxiosResponse } from 'axios';
// import fetchClient from './axiosInstance';
// import reportsApi from './reportsApi';
import { PageQueryParams } from '../models';
import { fetchClient, reportsApiClient as reportsApi } from './interceptor';

interface ComparisonQueryParams {
  measure: string;
  period_1_after: string;
  period_1_before: string;
  period_2_after: string;
  period_2_before: string;
  period_3_after?: string;
  period_3_before?: string;
  period_4_after?: string;
  period_4_before?: string;
  period_5_after?: string;
  period_5_before?: string;
  city?: string;
  brand?: string;
  barcode?: string;
  local_company?: string;
  size_of_package?: string;
  subcategory?: string;
  type_of_package?: string;
  active_stores?: boolean;
  validated_stores?: boolean;
}

interface ComparisonReportsQueryParams extends PageQueryParams {
  period_1_after: string;
  period_1_before: string;
  period_2_after: string;
  period_2_before: string;
  period_3_after?: string;
  period_3_before?: string;
  period_4_after?: string;
  period_4_before?: string;
  period_5_after?: string;
  period_5_before?: string;
  city_id?: string;
  brand_id?: string;
  barcode?: string;
  local_company_id?: string;
  size_of_package_id?: string;
  subcategory_id?: string;
  type_of_package_id?: string;
  common_stores?: boolean | string;
  validated_stores?: boolean;
}
export default class ComparisonService {
  static async getComparisonGraphByCompany(
    params: ComparisonQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/comparison/graph/local_company/`, { params, ...customConfig });
  }

  static async getComparisonGraphByBrand(
    params: ComparisonQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/comparison/graph/brand/`, { params, ...customConfig });
  }

  static async getComparisonGraphByBarcode(
    params: ComparisonQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/comparison/graph/barcode/`, { params, ...customConfig });
  }

  static async getComparisonTableByCompany(
    params: ComparisonQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/comparison/table/local_company/`, { params, ...customConfig });
  }

  static async getComparisonReportsTable(
    type: string,
    params: ComparisonReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/comparison/table/${type}`, { params, ...customConfig });
  }

  static async getComparisonTableByBrand(
    params: ComparisonQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/comparison/table/brand/`, { params, ...customConfig });
  }

  static async getComparisonTableByBarcode(
    params: ComparisonQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/comparison/table/barcode/`, { params, ...customConfig });
  }

  static async exportByType(
    type: string,
    params: ComparisonQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/comparison/table/${type}/export/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportReportsByType(
    type: string,
    params: ComparisonReportsQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return reportsApi.get(`/api/v1/comparison/export/${type}`, {
      params,
      ...customConfig,
      responseType: 'arraybuffer',
      headers: { 'Content-Type': 'blob' },
    });
  }

  static async exportDetailed(
    params: ComparisonQueryParams,
    customConfig?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return fetchClient.get(`/api/v1/comparison/detailed/`, {
      params,
      ...customConfig,
      // responseType: 'arraybuffer',
      // headers: { 'Content-Type': 'blob' },
    });
  }
}
