import { Card, Switch } from 'antd';
import DownRedIcon from '../../../assets/down-red.svg';
import UpGreenIcon from '../../../assets/up-green.svg';
import { useState } from 'react';

type Props = {
  pageName: string;
  tableData: any[];
  tab: string;
  periodLabels?: string[];
  isTabletOrMobile?: boolean;
  totalData?: any[];
};

const gridStyle: React.CSSProperties = {
  margin: 0,
  padding: 0,
};

export const ComparisonCard = ({ pageName, tableData, isTabletOrMobile, tab, periodLabels, totalData }: Props) => {
  const [isShareActive, setIsShareActive] = useState<boolean>(true);
  const defaultStyles: React.CSSProperties = {
    margin: 0,
    padding: 0,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontSize: isTabletOrMobile ? '11px' : '14px',
  };

  const onChangeSwitch = (value) => {
    if (value === true) {
      setIsShareActive(true);
    } else {
      setIsShareActive(false);
    }
  };

  return (
    <>
      {tableData?.map((item, index) => (
        <Card
          title={
            (pageName === 'barcode' && (
              <>
                <p style={defaultStyles}>{item.product_name}</p>
                <p
                  style={{
                    color: '#A9A9A9',
                    fontWeight: 600,
                    ...defaultStyles,
                  }}
                >
                  {item.brand} | {item.local_company}
                </p>
              </>
            )) ||
            (pageName === 'brand' && (
              <>
                <p style={defaultStyles}>{item.brand}</p>
                <p
                  style={{
                    color: '#A9A9A9',
                    fontWeight: 600,
                    ...defaultStyles,
                  }}
                >
                  {item.local_company}
                </p>
              </>
            )) ||
            (pageName === 'company' && <p style={defaultStyles}>{item.local_company}</p>)
          }
          key={item?.local_company + index}
          headStyle={{ backgroundColor: '#e8e8e8', fontWeight: 700 }}
          style={{ marginBottom: 20 }}
          bodyStyle={{ padding: 0 }}
        >
          <div style={{ display: 'flex', flexDirection: 'row', padding: 0, margin: 0 }}>
            <Card.Grid style={{ ...gridStyle, paddingLeft: 10 }}>Period</Card.Grid>
            <Card.Grid style={{ ...gridStyle, paddingLeft: 10, textAlign: 'end' }}>
              {tab?.charAt(0).toUpperCase() + tab?.slice(1)}
            </Card.Grid>
            <Card.Grid style={{ ...gridStyle, paddingLeft: 10, textAlign: 'end' }}>
              Change{' '}
              <Switch
                size="small"
                checked={isShareActive}
                onChange={onChangeSwitch}
                checkedChildren=""
                unCheckedChildren="%"
              />
            </Card.Grid>
          </div>
          {item?.data?.map((dataItem) => (
            <div key={dataItem.period} style={{ display: 'flex', flexDirection: 'row' }}>
              <Card.Grid style={{ ...gridStyle, fontSize: isTabletOrMobile ? '11px' : '14px', paddingLeft: 10 }}>
                <>
                  <p> {periodLabels[0][dataItem.periods] || dataItem?.periods}</p>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-start',
                      color: '#A9A9A9',
                      fontWeight: 400,
                      ...defaultStyles,
                    }}
                  >
                    Stores: {dataItem?.stores}
                  </p>
                </>
              </Card.Grid>
              <Card.Grid
                style={{
                  ...gridStyle,
                  padding: '0 10px 0 10px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <>
                  <p style={{ ...defaultStyles, textAlign: 'end' }}>
                    {`${dataItem?.[tab]}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                  </p>
                  <p
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'flex-end',
                      color: '#A9A9A9',
                      fontWeight: 400,
                      ...defaultStyles,
                    }}
                  >
                    Share: {dataItem?.[`share_${tab}`]} %
                  </p>
                </>
              </Card.Grid>
              <Card.Grid style={gridStyle}>
                {isShareActive ? (
                  <>
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: dataItem?.[`share_absolute_${tab}`] === '--' ? 'flex-end' : 'space-between',
                      }}
                    >
                      {dataItem?.[`share_absolute_${tab}`] === '--' ? (
                        ''
                      ) : (
                        <img
                          src={dataItem?.[`share_absolute_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                          alt="red"
                          style={{ paddingLeft: 5 }}
                        />
                      )}
                      <p style={{ ...defaultStyles, paddingRight: 5 }}>
                        Absolute: {dataItem?.[`share_absolute_${tab}`]} %
                      </p>
                    </span>

                    <span
                      style={{
                        display: 'flex',
                        justifyContent: dataItem?.[`share_relative_${tab}`] === '--' ? 'flex-end' : 'space-between',
                      }}
                    >
                      {dataItem?.[`share_relative_${tab}`] === '--' ? (
                        ''
                      ) : (
                        <img
                          src={dataItem?.[`share_relative_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                          alt="green"
                          style={{ paddingLeft: 5 }}
                        />
                      )}
                      <p style={{ ...defaultStyles, paddingRight: 5 }}>
                        Relative: {dataItem?.[`share_relative_${tab}`]} %
                      </p>
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      style={{
                        display: 'flex',
                        justifyContent: dataItem?.[`absolute_${tab}`] === '--' ? 'flex-end' : 'space-between',
                      }}
                    >
                      {dataItem?.[`absolute_${tab}`] === '--' ? (
                        ''
                      ) : (
                        <img
                          src={dataItem?.[`absolute_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                          alt="red"
                          style={{ paddingLeft: 5 }}
                        />
                      )}
                      <p style={{ ...defaultStyles, paddingRight: 5 }}>Absolute: {dataItem?.[`absolute_${tab}`]} %</p>
                    </span>

                    <span
                      style={{
                        display: 'flex',
                        justifyContent: dataItem?.[`relative_${tab}`] === '--' ? 'flex-end' : 'space-between',
                      }}
                    >
                      {dataItem?.[`relative_${tab}`] === '--' ? (
                        ''
                      ) : (
                        <img
                          src={dataItem?.[`relative_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                          alt="green"
                          style={{ paddingLeft: 5 }}
                        />
                      )}
                      <p style={{ ...defaultStyles, paddingRight: 5 }}>Relative: {dataItem?.[`relative_${tab}`]} %</p>
                    </span>
                  </>
                )}
              </Card.Grid>
            </div>
          ))}
        </Card>
      ))}
      <Card
        title={'Total'}
        style={{ marginBottom: 0 }}
        headStyle={{ backgroundColor: '#e8e8e8', fontWeight: 700 }}
        bodyStyle={{ padding: 0 }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', padding: 0, margin: 0 }}>
          <Card.Grid style={{ ...gridStyle, paddingLeft: 10 }}>Period</Card.Grid>
          <Card.Grid style={{ ...gridStyle, paddingLeft: 10, textAlign: 'end' }}>
            {tab?.charAt(0).toUpperCase() + tab?.slice(1)}
          </Card.Grid>
          <Card.Grid style={{ ...gridStyle, paddingLeft: 10, textAlign: 'end' }}>Change</Card.Grid>
        </div>
        {totalData?.map((dataItem) => (
          <div key={dataItem.periods[0].period_1} style={{ display: 'flex', flexDirection: 'row' }}>
            <Card.Grid
              style={{
                ...gridStyle,
                fontSize: isTabletOrMobile ? '11px' : '14px',
                padding: '0 10px 0 10px',
              }}
            >
              <>
                <p> {periodLabels[0][dataItem.periods] || dataItem?.period}</p>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    color: '#A9A9A9',
                    fontWeight: 400,
                    ...defaultStyles,
                  }}
                >
                  Stores: {dataItem?.stores}
                </p>
              </>
            </Card.Grid>
            <Card.Grid
              style={{
                ...gridStyle,
                padding: '0 10px 0 10px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <>
                <p style={{ ...defaultStyles, display: 'flex', justifyContent: 'flex-end' }}>
                  {`${dataItem?.[tab]}`?.replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1 ')}
                </p>
                <p
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    color: '#A9A9A9',
                    fontWeight: 400,
                    ...defaultStyles,
                  }}
                >
                  Share: {dataItem?.[`share_${tab}`]} %
                </p>
              </>
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              {isShareActive ? (
                <>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: dataItem?.[`share_absolute_${tab}`] === '--' ? 'flex-end' : 'space-between',
                    }}
                  >
                    {dataItem?.[`share_absolute_${tab}`] === '--' ? (
                      ''
                    ) : (
                      <img
                        src={dataItem?.[`share_absolute_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                        alt="red"
                        style={{ paddingLeft: 5 }}
                      />
                    )}
                    <p style={{ ...defaultStyles, paddingRight: 5 }}>
                      Absolute: {dataItem?.[`share_absolute_${tab}`]} %
                    </p>
                  </span>

                  <span
                    style={{
                      display: 'flex',
                      justifyContent: dataItem?.[`share_relative_${tab}`] === '--' ? 'flex-end' : 'space-between',
                    }}
                  >
                    {dataItem?.[`share_relative_${tab}`] === '--' ? (
                      ''
                    ) : (
                      <img
                        src={dataItem?.[`share_relative_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                        alt="green"
                        style={{ paddingLeft: 5 }}
                      />
                    )}
                    <p style={{ ...defaultStyles, paddingRight: 5 }}>
                      Relative: {dataItem?.[`share_relative_${tab}`]} %
                    </p>
                  </span>
                </>
              ) : (
                <>
                  <span
                    style={{
                      display: 'flex',
                      justifyContent: dataItem?.[`absolute_${tab}`] === '--' ? 'flex-end' : 'space-between',
                    }}
                  >
                    {dataItem?.[`absolute_${tab}`] === '--' ? (
                      ''
                    ) : (
                      <img
                        src={dataItem?.[`absolute_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                        alt="red"
                        style={{ paddingLeft: 5 }}
                      />
                    )}
                    <p style={{ ...defaultStyles, paddingRight: 5 }}>Absolute: {dataItem?.[`absolute_${tab}`]} %</p>
                  </span>

                  <span
                    style={{
                      display: 'flex',
                      justifyContent: dataItem?.[`relative_${tab}`] === '--' ? 'flex-end' : 'space-between',
                    }}
                  >
                    {dataItem?.[`relative_${tab}`] === '--' ? (
                      ''
                    ) : (
                      <img
                        src={dataItem?.[`relative_${tab}`] > 0 ? UpGreenIcon : DownRedIcon}
                        alt="green"
                        style={{ paddingLeft: 5 }}
                      />
                    )}
                    <p style={{ ...defaultStyles, paddingRight: 5 }}>Relative: {dataItem?.[`relative_${tab}`]} %</p>
                  </span>
                </>
              )}
            </Card.Grid>
          </div>
        ))}
      </Card>
    </>
  );
};
