import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Button, Col, Drawer, Dropdown, Menu, Radio, RadioChangeEvent, Row, Tabs } from 'antd';
import { FilterOutlined, LineChartOutlined, TableOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons';
import qs from 'qs';
import { useMediaQuery } from 'react-responsive';

import { ComparisonCategory } from './ComparisonCategory';
import { CustomFilter } from '../../components/filter/CustomFilter';
import { RadioGroupMenu } from '../../components/RadioGroupMenu/RadioGroupMenu';
import ComparisonService from '../../services/comparison';
// import { downloadFile } from '../../utils/downloadFile';
import { isTobacco } from '../../utils/isTobacco';
import { openNotification } from '../../utils/lastThreeMonths';
import styles from './styles.module.scss';
import { DownloadsDrawer } from '../../components/DownloadsDrawer/DownloadsDrawer';
import { downloadFile } from '../../utils/downloadFile';

const ExportMenuItems = (onDownload: (type: 'detailed' | 'sales') => void) => (
  <Menu>
    {/* <Menu.Item key="detailed">
      <div onClick={() => onDownload('detailed')}>Detailed</div>
    </Menu.Item> */}
    <Menu.Item key="sales">
      <div onClick={() => onDownload('sales')}>By barcode</div>
    </Menu.Item>
  </Menu>
);

export const ComparisonReportsPage = () => {
  const { type, tab } = useParams<{ type: string; tab: string }>();
  const history = useHistory();
  const location = useLocation();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 872px)' });
  const [currentTab, setCurrentTab] = useState<string>(tab || 'value');
  const [openFilter, setOpenFilter] = useState(false);
  const [exportLoading, setExportLoading] = useState<boolean>(false);
  const params = qs.parse(location.search, { ignoreQueryPrefix: true }) as Record<string, string>;
  const [page, setPage] = useState<string>('company');
  const [mode, setMode] = useState<string>('table');
  const [isOpenDownloads, setIsOpenDonwloads] = useState<boolean>(false);

  const onChangePage = ({ target: { value } }: RadioChangeEvent) => {
    setPage(value);
    history.replace({
      pathname: `/reports/comparison/${value}/${tab}`,
      search: history.location.search,
    });
  };

  const onChangeMode = (e: RadioChangeEvent) => {
    setMode(e.target?.value);
  };

  const operations = (
    <Row>
      <Col>
        <Radio.Group defaultValue="table" buttonStyle="solid" onChange={onChangeMode}>
          <Radio.Button value="table"> {isTabletOrMobile ? <TableOutlined /> : 'Table'}</Radio.Button>
          <Radio.Button value="graphic">{isTabletOrMobile ? <LineChartOutlined /> : 'Graphic'}</Radio.Button>
        </Radio.Group>
      </Col>
    </Row>
  );

  useEffect(() => {
    if (location.pathname.includes('company')) {
      setPage('company');
    } else if (location.pathname.includes('brand')) {
      setPage('brand');
    } else if (location.pathname.includes('barcode')) {
      setPage('barcode');
    }
    return () => {
      setPage('company');
    };
  }, [location.pathname]);

  const onOpenFilter = () => {
    setOpenFilter(true);
  };

  const onFilterChange = () => {
    setOpenFilter(false);
  };

  const hide = () => {
    setOpenFilter(false);
  };

  const closeDownloads = () => {
    setIsOpenDonwloads(false);
  };

  const tabs = useMemo(
    () => [
      {
        label: 'Value',
        key: 'value',
        children: <ComparisonCategory tab={currentTab} pageName={type || ''} mode={mode} />,
      },
      {
        label: 'Items',
        key: 'items',
        children: <ComparisonCategory tab={currentTab} pageName={type || ''} mode={mode} />,
      },
      !isTobacco() && {
        label: 'Volume',
        key: 'volume',
        children: <ComparisonCategory tab={currentTab} pageName={type || ''} mode={mode} />,
      },
    ],
    [currentTab, type, mode]
  );

  const onChange = useCallback((key: string) => {
    setCurrentTab(key);
    // history.replace({
    //   pathname: `/reports/comparison/${type}/${key}`,
    //   search: history.location.search,
    // });
  }, []);

  const handleDownloadFile = (exportType: 'detailed' | 'sales') => {
    setExportLoading(true);
    if (exportType === 'detailed') {
      ComparisonService.exportDetailed({
        measure: currentTab,
        period_1_after: params?.period_1_after,
        period_1_before: params?.period_1_before,
        period_2_after: params?.period_2_after,
        period_2_before: params?.period_2_before,
        // size: +params?.limit || 10,
        // city_id: params?.city,
        // common_stores: params.active_stores,
        // subcategory_id: params?.subcategory,
        // local_company_id: params?.local_company,
        // brand_id: params.brand,
        ...params,
      })
        .then((res) => {
          // if (res.data) {
          //   setIsOpenDonwloads(true);
          //   openNotification('File added to downloads folder', 'success');
          // }
          downloadFile(res.data, `detailedReport_${type}_${params?.months}` || 'detailed_report');
        })
        .catch((err) => openNotification('Something went wrong', 'error'))
        .finally(() => setExportLoading(false));
    } else {
      ComparisonService.exportReportsByType(type === 'company' ? 'local_company' : type, {
        // measure: currentTab,
        period_1_after: params?.period_1_after,
        period_1_before: params?.period_1_before,
        period_2_after: params?.period_2_after,
        period_2_before: params?.period_2_before,
        size: +params?.limit || 10,
        city_id: params?.city,
        common_stores: !!params.active_stores,
        subcategory_id: params?.subcategory,
        local_company_id: params?.local_company,
        brand_id: params.brand,
        ...params,
      })
        .then((res) => {
          const fileName =
            `comparison_reports_by_${type}_${params?.period_1_after}_${params?.period_2_before}` || 'detailed_report';
          downloadFile(res.data, fileName);
        })
        .catch((err) => openNotification('Something went wrong', 'error'))
        .finally(() => setExportLoading(false));
    }
  };

  return (
    <>
      <div className={styles.company_layout_content}>
        <div className={styles.top_bar}>
          <div className={styles.wrapper}>
            {!isTabletOrMobile && (
              <div className={styles.title}>
                <RadioGroupMenu onChange={onChangePage} value={page} />
              </div>
            )}
            {isTabletOrMobile && (
              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', overflowX: 'auto' }}>
                <div>
                  <Drawer
                    title=""
                    placement="left"
                    onClose={hide}
                    open={openFilter}
                    width={isTabletOrMobile ? '320' : undefined}
                  >
                    <CustomFilter type={type} onFilterChange={onFilterChange} pageName="comparison" onCancel={hide} />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  />
                </div>
              </div>
            )}
            {!isTabletOrMobile && (
              <>
                <div style={{ marginLeft: '15px' }}>
                  <Drawer title="" placement="left" onClose={hide} open={openFilter}>
                    <CustomFilter type={type} onFilterChange={onFilterChange} pageName="comparison" onCancel={hide} />
                  </Drawer>
                  <Button
                    type="default"
                    className={styles.company_filter_btn}
                    onClick={onOpenFilter}
                    icon={<FilterOutlined />}
                  >
                    &nbsp;&nbsp;Filter
                  </Button>
                </div>
              </>
            )}
          </div>
          {/* {page === 'barcode' && ( */}
          {/* <Dropdown overlay={ExportMenuItems(handleDownloadFile)}> */}
          <Button
            type="default"
            className={styles.export_btn}
            loading={exportLoading}
            onClick={() => handleDownloadFile('sales')}
          >
            <VerticalAlignBottomOutlined />
            Export
          </Button>
          {/* </Dropdown> */}
          {/* )} */}
          {/* {page !== 'barcode' && (
            <Button
              type="default"
              className={styles.export_btn}
              onClick={() => handleDownloadFile('sales')}
              loading={exportLoading}
            >
              <VerticalAlignBottomOutlined />
              Export
            </Button>
          )} */}
        </div>
        <Tabs defaultActiveKey={currentTab} onChange={onChange} items={tabs} tabBarExtraContent={operations} />
      </div>
      <Drawer title="Downloads" width="auto" placement="right" onClose={closeDownloads} open={isOpenDownloads}>
        <DownloadsDrawer open={isOpenDownloads} />
      </Drawer>
    </>
  );
};
